.banner {
  background-color: var(--secondary-800);
  padding: 10px;
  width: fit-content;
  border-radius: 12px;
    .banner-img {
    width: 450px;
    height: 100px;
    background-color: var(--secondary-700);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    margin-bottom: 5px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 12px;
    }
  }

  .banner-select{
    max-width: 450px;
  }
}
