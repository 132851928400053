.circle-container {
  display: flex;
  gap: 10px; /* Adjust the gap between circles */
}

.circle {
  width: 30px; /* Adjust the size of the circles */
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: white;
}

.win {
  background-color: green;
}

.loss {
  background-color: red;
}
.tipster{

  .img {
    height: 178px;
    background-color: var(--secondary-900);
    display: flex !important;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 342%;
    .profile-icon {
      font-size: 60px;
      color: var(--secondary-400);
    }
  }
  label {
    position: absolute;
    bottom: 8px;
    right: 8px;
    background-color: $primary-400;
    color: var(--secondary-100);
    border-radius: 100%;
    font-size: $fs-20;
    padding: 7px 6px 7px 7px;
    cursor: pointer;
    i {
      margin: 0 -1px -2px 0;
    }
  }
}
.image-remove{
  text-align: end;
}